.topics .topicsScore .el-form-item__content {
  margin-left: 0.5rem !important;
}
.activeConfig h4 {
  font-size: 18px;
  padding: 1rem 2rem;
}
.activeConfig h4:before {
  content: '';
  border-left: 3px solid #2878ff;
  margin-right: 10px;
}
